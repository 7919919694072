/* eslint-disable */
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCheckbox,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonSelect,
    IonTitle,
    IonSelectOption,
    IonNote,
    IonText,
} from '@ionic/react';
import Toolbar from '../components/ToolBar';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useHistory } from 'react-router-dom'
import { validStates, validEnvs, validDomains} from '../common_data';

import React, { useEffect, useState }  from 'react';

const shipping_map = require('../assets/shipping_map.png');
const test_zap_url = 'https://hooks.zapier.com/hooks/catch/2277921/3hrcptl/'
const prod_zap_url = 'https://hooks.zapier.com/hooks/catch/2277921/3hiopgs/'


function getCurrentDateTime() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}`;
}


async function createSpreadsheetRow(data: any, orderSubmitted: string) {

    if (data['virtualInstall'] == true) {
        data['virtualInstall'] = 'YES'
    }

    if (data['virtualInstall'] == false) {
        data['virtualInstall'] = 'No'
    }

    const payload = {
        organization: data['ordererOrganization'],
        clinicianName: data['ordererName'],
        clinicianEmail: data['ordererEmail'],
        clinicianPhoneNumber: data['ordererPhoneNumber'].replace(/\D/g, ''),
        inventorySubset: data['inventorySubset'],
        date: orderSubmitted,
        kitContents: data['kitContents'],
        warehouseComments: data['warehouseComments'],
        patientName: data['patientName'],
        phoneNumber: data['patientPhoneNumber'].replace(/\D/g, ''),
        address1: data['patientAddress1'],
        address2: data['patientAddress2'],
        city: data['patientCity'],
        state: data['patientState'],
        zip: data['patientPostalCode'],
        patientId: data['patientId'],
        serviceType: data['shippingSpeedSelect'],
        hrsKitNumber: 'N/A',
        supportTicketNumber: 'N/A',
        orderType: 'Order',
        itemWithIssue: 'N/A',
        issueType: 'N/A',
        virtualInstall: data['virtualInstall'],
        returnsCoordination: 'N/A',
        resubmission: 'N/A',
        orderNumberResubmission: 'N/A',
        orderNumberWithIssue: 'N/A',
        tier: 'Premium',
        needReplacement: 'N/A',
    }

    const res = await fetch(prod_zap_url, {
        method: "POST",
        body: JSON.stringify(payload)
    });

    if (!res.ok) {
        const message = `An error has occured: ${res.status}`;
        throw new Error(message);
    }

    return await res.json();
}

const StandardOrder: React.FC = () => {
    const history = useHistory();
    const [isFormValid, setIsFormValid] = useState(false);

    const {
        handleSubmit,
        control,
        setValue,
        register,
        getValues,
        formState: { isValid, errors }
    } = useForm({
        defaultValues: {
            ordererName: '',
            ordererPhoneNumber: '',
            ordererEmail: '',
            ordererOrganization: '',
            inventorySubset: '',
            virtualInstall: false,
            licenseType: '',
            kitContents: '',
            shippingSpeedSelect: 'Ground',
            patientName: '',
            patientPhoneNumber: '',
            patientAddress1: '',
            patientAddress2: '',
            patientCity: '',
            patientState: '',
            patientPostalCode: '',
            patientId: '',
            warehouseComments: '',
        }
    });


    useEffect(() => {
        setIsFormValid(isValid);
    }, [isValid]);
      

    /**
     *
     * @param data
     */
    const onSubmit = async (data: any) => {

        if (isFormValid) {
            const orderSubmitted = getCurrentDateTime();
    
            createSpreadsheetRow(data, orderSubmitted).then(res => {
                history.push('/thanks')
            }).catch(error => {
                alert(`${error.message}: please contact support@healthrecoverysolutions.com`)
                console.log(error.message);
            });
        }
        else {
            alert('Form is invalid')
        }
        
    };

    let hasFocus = false;


    return (
        <IonPage>
            <Toolbar />
            <IonContent fullscreen className="ion-padding">
                <IonTitle class='order-title'>New Order Form</IonTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <IonCard>
                        <IonCardContent>
                            <IonItem>
                                <IonLabel>Orderer Name *</IonLabel>
                                <IonInput
                                    {...register('ordererName', {
                                        required: 'Orderer Name is a required field',
                                    })}
                                />
                            </IonItem>
                            <ErrorMessage
                                errors={errors}
                                name="ordererName"
                                as={<div style={{ color: 'red' }} />}
                            />

                            <IonItem>
                                <IonLabel>Orderer Email * </IonLabel>
                                <IonInput
                                    {...register('ordererEmail', {
                                        required: 'Orderer Email is a required field',
                                        validate: (value, _) => validDomains.includes(value.split('@')[1].toLowerCase()) || "This is not a valid email. For help, please call 551-203-0011 or email support@healthrecoverysolutions.com",
                                        maxLength: 100,
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: 'Invalid email address'
                                        }
                                    })}
                                />
                                {errors.ordererEmail && errors.ordererEmail.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                            </IonItem>
                            <ErrorMessage
                                errors={errors}
                                name="ordererEmail"
                                as={<div style={{ color: 'red' }} />}
                            />
                            <IonItem lines='none'>
                                <IonNote>
                                    Note: This email address will receive shipping updates
                                </IonNote>
                            </IonItem>

                            <IonItem>
                                <IonLabel>Orderer Phone Number * </IonLabel>
                                <IonInput
                                    {...register('ordererPhoneNumber', {
                                        required: 'Orderer Phone Number is a required field',
                                        pattern: {
                                            value: /^(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/gm,
                                            message: 'Invalid phone number'
                                        }
                                    })}
                                />
                            </IonItem>
                            <ErrorMessage
                                errors={errors}
                                name="ordererPhoneNumber"
                                as={<div style={{ color: 'red' }} />}
                            />
                            <IonItem>
                                <IonLabel>Orderer Organization * </IonLabel>
                                <IonInput
                                    {...register('ordererOrganization', {
                                        required: 'Orderer Organization is a required field',
                                        validate: (value, _) => validEnvs.includes(value) || "This is not a valid name. For help, please call 551-203-0011 or email support@healthrecoverysolutions.com"
                                    })}
                                />
                            </IonItem>
                            <ErrorMessage
                                errors={errors}
                                name="ordererOrganization"
                                as={<div style={{ color: 'red' }} />}
                            />

                            <IonItem>
                                <IonLabel>Inventory Subset </IonLabel>
                                <IonInput
                                    {...register('inventorySubset')}
                                />
                            </IonItem>

                        </IonCardContent>
                    </IonCard>
                    <IonCard>
                        <IonCardContent>
                            <IonItem>
                                <IonLabel>ClinicianConnect Patient ID</IonLabel>
                                <IonInput {...register('patientId')}/>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Should this patient receive the Remote Patient Onboarding service?</IonLabel>
                                <Controller
                                    name="virtualInstall"
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <IonCheckbox
                                                checked={field.value}
                                                onIonChange={e => {
                                                    setValue('virtualInstall', e.detail.checked);
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </IonItem>
                            <IonItem lines='none'>
                                <IonNote><strong>Note: Service will only be provided if your organization is signed up for the Remote Patient Onboarding service.</strong> This service includes delivery status tracking and patient equipment education and installation which is initiated via phone call and/or text. Please contact your account representative if you’re interested in adding this service.</IonNote>
                            </IonItem>
                        </IonCardContent>
                    </IonCard>
                    <IonCard>
                        <IonCardContent>
                            <IonItem>
                                <IonLabel>Kit Contents *</IonLabel>
                                <Controller
                                    render={({ field: { onChange, value } }) => (
                                        <IonSelect
                                            placeholder="Select Kit Contents"
                                            value={value}
                                            key={value}
                                            onFocus={() => (hasFocus = !hasFocus)}
                                            onIonChange={(e) => hasFocus && onChange(e.detail.value)}
                                            multiple={true}
                                        >
                                            <IonSelectOption value="Tablet">Tablet</IonSelectOption>
                                            <IonSelectOption value="Standard Scale">Standard Scale</IonSelectOption>
                                            <IonSelectOption value="Bariatric Scale">Bariatric Scale</IonSelectOption>
                                            <IonSelectOption value="BP Monitor w/ SM Cuff">BP w/ Small Cuff</IonSelectOption>
                                            <IonSelectOption value="BP Monitor w/ MD Cuff">BP w/ Medium Cuff</IonSelectOption>
                                            <IonSelectOption value="BP Monitor w/ LG Cuff">BP w/ Large Cuff</IonSelectOption>
                                            <IonSelectOption value="Pulse Oximeter">Pulse Oximeter</IonSelectOption>
                                            <IonSelectOption value="Thermometer">Thermometer</IonSelectOption>
                                            <IonSelectOption value="Glucometer">Glucometer</IonSelectOption>
                                        </IonSelect>
                                    )}
                                    control={control}
                                    name="kitContents"
                                    rules={{ required: 'Kit Contents is a required field' }}
                                />
                            </IonItem>
                            <IonItem lines='none'>
                                <IonNote>
                                    <IonText>Note:</IonText>
                                    <ul>
                                        <li>Must order a kit type that your organization is contracted for.</li>
                                        <li>Tablet comes with 2 charging cords and 2 charging blocks</li>
                                        <li>Glucometer comes with 1 box of 50 test strips, 1 box of 100 lancets, 1 lancing device, control solution</li>
                                        <li>Thermometer comes with ear probe covers</li>
                                        <li>All kits that have peripheral devices included also come with extra batteries</li>
                                    </ul>  
                                </IonNote>
                            </IonItem>

                            <ErrorMessage
                                errors={errors}
                                name="kitContents"
                                as={<div style={{ color: 'red' }} />}
                            />
                        </IonCardContent>
                    </IonCard>

                    <IonCard>
                        <IonCardContent>
                            <IonCardTitle class="card-title">Ship to Address</IonCardTitle>
                            <IonItem>
                                <IonLabel>Full Name * </IonLabel>
                                <IonInput
                                    {...register('patientName', {
                                        required: 'Full Name is a required field',
                                        maxLength: 100
                                    })}
                                />
                                {errors.patientName && errors.patientName.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                            </IonItem>
                            
                            <ErrorMessage
                                errors={errors}
                                name="patientName"
                                as={<div style={{ color: 'red' }} />}
                            />

                            <IonItem>
                                <IonLabel>Phone Number * </IonLabel>
                                <IonInput
                                    {...register('patientPhoneNumber', {
                                        required: 'Phone Number is a required field',
                                        pattern: {
                                            value: /^(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/gm,
                                            message: 'Invalid phone number'
                                        }
                                    })}
                                />
                            </IonItem>
                            <ErrorMessage
                                errors={errors}
                                name="patientPhoneNumber"
                                as={<div style={{ color: 'red' }} />}
                            />


                            <IonItem>
                                <IonLabel>Address 1 * </IonLabel>
                                <IonInput
                                    {...register('patientAddress1', {
                                        required: 'Address 1 is a required field',
                                        maxLength: 30
                                    })}
                                />
                                {errors.patientAddress1 && errors.patientAddress1.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                            </IonItem>

                            <ErrorMessage
                                errors={errors}
                                name="patientAddress1"
                                as={<div style={{ color: 'red' }} />}
                            />

                            <IonItem>
                                <IonLabel>Address 2 </IonLabel>
                                <IonInput
                                    {...register('patientAddress2', {
                                        maxLength: 30
                                    })}
                                />
                                {errors.patientAddress2 && errors.patientAddress2.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                            </IonItem>

                            <IonItem>
                                <IonLabel>City *</IonLabel>
                                <IonInput
                                    {...register('patientCity', {
                                        required: 'City is a required field',
                                        maxLength: 25
                                    })}
                                />
                                {errors.patientCity && errors.patientCity.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                            </IonItem>
                            <ErrorMessage
                                errors={errors}
                                name="patientCity"
                                as={<div style={{ color: 'red' }} />}
                            />

                            <IonItem>
                                <IonLabel>State *</IonLabel>
                                <IonInput
                                    {...register('patientState', {
                                        required: 'State is a required field',
                                        pattern: {
                                            value: /^[A-Z]{2}$/i,
                                            message: 'Invalid State code, please use two capital letters',
                                        },
                                        validate: (value, _) => validStates.includes(value.toUpperCase()) || "Invalid State"
                                    })}
                                />
                            </IonItem>
                            <ErrorMessage
                                errors={errors}
                                name="patientState"
                                as={<div style={{ color: 'red' }} />}
                            />

                            <IonItem>
                                <IonLabel>Postal Code *</IonLabel>
                                <IonInput
                                    {...register('patientPostalCode', {
                                        required: 'Postal Code is a required field',
                                        pattern: {
                                            value: /^[0-9]{5}(?:-[0-9]{4})?$/i,
                                            message: 'Invalid postal code'
                                        }
                                    })}
                                />
                            </IonItem>
                            <ErrorMessage
                                errors={errors}
                                name="patientPostalCode"
                                as={<div style={{ color: 'red' }} />}
                            />

                            <IonItem>
                                <IonLabel>Shipping Speed *</IonLabel>
                                <Controller
                                    render={({ field }) => (
                                        <IonSelect
                                            placeholder="Select Shipping Speed"
                                            value={field.value}
                                            onIonChange={e => setValue('shippingSpeedSelect', e.detail.value)}
                                            interface="action-sheet"
                                        >
                                            <IonSelectOption value="Ground">Ground</IonSelectOption>
                                            <IonSelectOption value="2nd Day Air">2nd Day</IonSelectOption>
                                            <IonSelectOption value="Next Day Air Saver">Next Day</IonSelectOption>

                                        </IonSelect>
                                    )}
                                    control={control}
                                    name="shippingSpeedSelect"
                                    rules={{ required: 'Shipping Speed is a required field' }}
                                />
                            </IonItem>

                            <IonItem lines='none' style={{ display: 'flex', justifyContent: 'center' }}>
                                <IonNote style={{display: 'block', margin: 'auto '}}>
                                    Shipping times for Ground speed
                                </IonNote>
                            </IonItem>
                            <IonItem lines='none' style={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={shipping_map} style={{ display: 'block', margin: 'auto' }} />
                            </IonItem>

                            <ErrorMessage
                                errors={errors}
                                name="shippingSpeedSelect"
                                as={<div style={{ color: 'red' }} />}
                            />
                            

                        </IonCardContent>
                    </IonCard>

                    <IonCard>
                        <IonCardContent>
                            <IonCardTitle class="card-title">
                                Additional Information
                            </IonCardTitle>
                            <IonItem>
                                <IonLabel text-wrap>Notes for Warehouse Team</IonLabel>
                                <IonInput
                                    {...register('warehouseComments', {
                                        maxLength: 35
                                    })}
                                />
                                {errors.warehouseComments && errors.warehouseComments.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                            </IonItem>
                        </IonCardContent>
                    </IonCard>

                    <IonCard>
                        <IonCardContent>
                            {Object.keys(errors).length > 0 && (
                                <div style={{ color: 'red' }}>Invalid entries, please check information entered and try again</div>
                            )}
                            <IonButton className="order-form-btn" expand="block" type="submit">
                                Submit Order
                            </IonButton>
                            <IonItem lines='none'>
                                <IonNote>
                                    <ul>
                                        <li>Same day shipment for orders placed by 1:00 PM ET on business days when kits are in stock.</li>
                                        <li>Signature is required on all deliveries.</li>
                                    </ul>
                                </IonNote>
                            </IonItem>
                        </IonCardContent>
                    </IonCard>
                </form>
            </IonContent>
        </IonPage>
    );
};

export default StandardOrder;