export const validStates: string[] = [  'AL','AK','AZ','AR','CA','CO','CT','DE','DC','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD',
                                        'MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD',
                                        'TN','TX','UT','VT','VA','WA','WV','WI','WY'
                                    ]
    
export const validEnvs: string[] = [
    "ACareConnection",
    "Abramson",
    "Adena",
    "AdirondackHealth",
    "24223adorationhh",
    "AdvancedHH",
    "AdventistAmbulatory",
    "AdventistHomeCare",
    "AlleghenyHealthNetwork", 
    "AllegianceHealth",
    "AmericanHomecare",
    "Ancora",
    "Androscoggin",
    "AnewCare",
    "AppalachianRegionalHealthSystem",
    "AtHomeNursing",
    "AtlanticHealth",
    "Avera",
    "AveraAddictionCenter",
    "BaptistMemorial",
    "BartonHealth",
    "BayCareHome",
    "BaycareInnovation",
    "BeHealthyAtHome",
    "BeebeHealthcare",
    "BeebeHospitalAH",
    "BellinHealth",
    "BethIsraelLahey (NOT BethIsrael)",
    "BethesdaINNUPilot",
    "BJC",
    "BlossomRidge",
    "20414BluegrassCN",
    "Brockton",
    "Brookdale",
    "BrooksRehab",
    "BVHealth",
    "CanoHealth",
    "CapeRMC",
    "CapitalCaring",
    "AIPCapitalC",
    "CarePartnersHospice",
    "CareResource",
    "CatholicHealth",
    "CatskillsHospice",
    "CentralVermont",
    "Centura",
    "Chapters",
    "CHI",
    "CHSLongIsland",
    "Cigna",
    "CollegeStScholastica",
    "CommunityMA",
    "CommunityMAHospice",
    "CommunityNurse",
    "CommunityVNA",
    "Compassus",
    "Contessa",
    "CornerstoneEnsign",
    "CovenantCare",
    "DeltaHospital",
    "DeltaHCM",
    "Destinyhomehealth",
    "DignityHealth",
    "DoctorsChoice",
    "Eden",
    "ElaraCaring",
    "ElizabethHospice",
    "Emerest",
    "Emerson",
    "ErieVNA",
    "Essentia",
    "ESSHI",
    "EthosCare",
    "ExcelaHealth",
    "FairfieldVNA",
    "FaithRegional",
    "FIdelity",
    "FMH",
    "Genesis",
    "GeriatricSolutions",
    "19729graniteVNA",
    "HackensackMHAH",
    "HarborsHH",
    "HarriottHH",
    "24122hcconnectors",
    "HenryFord",
    "HHF",
    "HillsandDales",
    "HollandHospital2",
    "HomePhysiciansGroup",
    "HopeHCFlorida",
    "Hope",
    "HospiceCare",
    "HousecallProviders",
    "IndianRiverHC",
    "InfinityCaresHH",
    "UpstateInterimHC",
    "Iredell",
    "IUHealth",
    "JHHH",
    "18342KadlecRegional",
    "KansasHomeHealth",
    "KareInHome",
    "KVNA",
    "KeystoneHomeHealth",
    "Lakeland",
    "LamoilleHHH",
    "Liberty",
    "LifeEMS",
    "LightwaysHospice",
    "LorienHealth",
    "MHealthFairview",
    "MaineHealthSystem",
    "MaineHealthSystem2",
    "MarshallMC",
    "Marshfield",
    "MasonicVillages",
    "MedStar",
    "TiffinHomeCare",
    "Mercy",
    "MercyOne",
    "MidCoast",
    "Midland",
    "MissionHealth",
    "MissionCarePartners",
    "MomentsHospice",
    "MountainStates",
    "21169MultiCare",
    "VNANY",
    "Nashoba",
    "NHCParamedics",
    "NewHaven",
    "23083NFLLabrador",
    "NormanRegional",
    "NorthMississippiHS",
    "NorthwellResearch",
    "NorwellHospice",
    "NorwellVNA",
    "OahuHomeHC",
    "OCCK",
    "OhioLiving",
    "OSPTA",
    "PalomarHH",
    "Partners",
    "PennHighlands",
    "19197PennHomeHealth",
    "PersonalTouch",
    "PreferredCareHHS",
    "PremierPoint",
    "ProMedica",
    "ProvidenceAlaska",
    "ProvidenceCommunity",
    "ProvidenceHS",
    "Providence",
    "PSCCommunityServices",
    "PugetSound",
    "PureHealthcare",
    "QualityHomeHealth",
    "RedstoneAtHome",
    "Retreat",
    "RiverValley",
    "RMCHCS",
    "RSFHospitalAtHome",
    "RoyalCare",
    "RoyalCareCertified",
    "RWJBarnabas",
    "SacredHeart",
    "SapphireSC",
    "ScheurerHealth",
    "SeaMarCHC",
    "Sentara",
    "SharpHealthCare",
    "Signature",
    "SilverCrossHospital",
    "SouthcoastVNA",
    "SpectrumHealth",
    "StBernards",
    "StGabrielsHealth",
    "StLukes",
    "SterlingWellness",
    "StoneriseHomeHealth",
    "SuburbanHomeHealth",
    "Suffolk",
    "SummitWestCare",
    "SUNYUpstate",
    "Superior",
    "TheMedicalTeam",
    "TheraCare",
    "TNQualityCare",
    "TransitionsLifeCare",
    "UFShands",
    "UnityPoint",
    "22427UnivHospitalSA",
    "UPENN",
    "UPENNLGH",
    "UPPeoria",
    "VCUHealthRHWP",
    "VNAHSouthwestRegion",
    "VNACare",
    "VNASouthernManchester",
    "VNAMaryland",
    "VNASantaBarbara",
    "VNATC",
    "VNAPhiladelphia",
    "LowerValley",
    "VNSWestchester",
    "VNSNYB13294",
    "WaianaeCCHC",
    "Waveny",
    "WoosterCH",
    "25611angelahospice",
    "22123PennStateHershey",
    "AddisonCountyHH",
    "BethesdaNewtrition",
    "CareDimensions",
    "CareIV",
    "CHPCBuffalo",
    "ConcordiaVNA",
    "CookevilleRegionalHH",
    "DarlingsHomeCare",
    "EvaraHealth",
    "JewishSeniorServices",
    "JosephineAtHome",
    "KeystoneCare",
    "LimaHomeCare",
    "Mercyhealth",
    "Meritan",
    "NorthernLightHealth",
    "PhysiciansSWash",
    "PIHHealth",
    "PunxsutawneyAreaHospital",
    "QLSFamilyHomeHealth",
    "ScotlandHealth",
    "ShannonMedical",
    "StCamillus",
    "StFrancis",
    "TeleiosCN",
    "UNCHealth",
    "ValleyHomeCare",
    "VCU",
    "VNAofAlbany",
    "19929molina",
    "MohawkValley",
    "UniversityofMaryland",

]

export const validDomains: string[] = [
    "healthrecoverysolutions.com",
    "communityvna.org",
    "momentshospice.com",
    "bannerhealth.com",
    "hollandhospital.org",
    "hfhs.org",
    "mcvh-vcu.edu",
    "atlantichealth.org",
    "mhcah.org",
    "mc.duke.edu",
    "saintfrancis.com",
    "vcuhealth.org",
    "jseniors.org",
    "vnsny.org",
    "ksbhospital.com",
    "hopehealthco.org",
    "revivalhhc.org",
    "jpshealth.org",
    "yumaregional.org",
    "care4.com",
    "spartahospital.com",
    "sentara.com",
    "elara.com",
    "compassus.com",
    "duncanregional.com",
    "peninsula.org",
    "northernlight.org",
    "commonspirit.org",
    "camillus.org",
    "molinahealthcare.com",
    "ssmhealth.com",
    "marshallmedical.org",
    "sharp.com",
    "caredimensions.org",
    "mvhealthsystem.org",
    "direct.med.umich.edu",
    "androscoggin.org",
    "ahch.org",
    "mainegeneral.org",
    "uchospitals.edu",
    "unitypoint.org",
    "providence.org",
    "vnacare.org",
    "holyredeemer.com",
    "rwjbh.org",
    "jhu.edu",
    "faithkc.com",
    "lhha.org",
    "pphhealth.com",
    "brooksrehab.org",
    "chsli.org",
    "vnaalbany.org",
    "valleyhealth.com",
    "healthrecoverysolutions.com",
    "feeltheadvantage.com",
    "mercy.com",
    "teleioscn.org",
    "balladhealth.org",
    "halifaxregional.com",
    "leememorial.org",
    "leehealth.org",
    "vnatc.com",
    "chihealthathome.com",
    "bellin.org",
    "477home.org",
    "chesapeakeregional.com",
    "crmchealth.org",
    "metrogr.org",
    "phhealthcare.org",
    "bshsi.org",
    "iuhealth.org",
    "bjc.org",
    "theracarehh.com",
    "hcnetwork.org",
    "vnshealth.org",
    "vnaphilly.org",
    "visitingnurse.net",
    "canohealth.com",
    "brown.edu",
    "emersonhosp.org",
    "deermeadowshomehealth.org",
    "vns.org",
    "baycare.org",
    "southcoast.org",
    "kh.org",
    "nahealth.com",
    "myhhhh.org",
    "mmc.org",
    "essentiahealth.org",
    "medstar.net",
    "bmhcc.org",
    "lakewoodhealthsystem.com",
    "cghmc.com",
    "providencechc.org",
    "healthrecoverysoluitons.com",
    "allina.com",
    "avera.org",
    "3hc.org",
    "kumc.edu",
    "shhcsg.com",
    "healthcareathome.com",
    "highmark.com",
    "hdghmi.org",
    "mjcare.com",
    "communitynurse.com",
    "lifeems.com",
    "bilh.org",
    "lahey.org",
    "vidanthealth.com",
    "tmh.org",
    "alvarezandmarsal.com",
    "chsbuffalo.org",
    "midcoasthealth.com",
    "promedica.org",
    "constellationhs.com",
    "southshorehealth.org",
    "homephysiciansgroup.com",
    "shmc.org",
    "brocktonvna.org",
    "mclaren.org",
    "fmh.org",
    "theaustinnetwork.com",
    "healthybaldwin.org",
    "allegiancehealth.org",
    "nrh-ok.com",
    "scotlandhealth.org",
    "vnacarenetwork.org",
    "pennmedicine.upenn.edu",
    "ahn.org",
    "fairview.org",
    "brookshealth.org",
    "hmc.psu.edu",
    "uchicagomedicine.org",
    "nmhs.net",
    "mymlc.com",
    "trucarehomehealth.com",
    "multicare.org",
    "utsouthwestern.edu",
    "shands.ufl.edu",
    "premierhealth.com",
    "amberhomecare.com",
    "sacredheartkc.com",
    "bgcarenav.org",
    "ancoraalaska.com",
    "sequoiahhh.com",
    "wchosp.org",
    "ropersaintfrancis.com",
    "med.umich.edu",
    "tmmc.com",
    "centura.org",
    "providencehcare.com",
    "nvna.org",
    "beebehealthcare.org",
    "doctorschoicefl.com",
    "hillsanddales.com",
    "bartonhealth.org",
    "waveny.org",
    "mvnhealth.com",
    "firsthealth.org",
    "tnquality.com",
    "stmaryskids.org",
    "palomarhealth.org",
    "pennstatehealth.org",
    "kadlec.org",
    "vnab.org",
    "jhmi.edu",
    "range.fairview.org",
    "ssmhc.com",
    "scheurer.org",
    "mhsjvl.org",
    "occk.com",
    "centricacare.org",
    "qualitylifeservices.com",
    "adorationhealth.com",
    "harriotthomehealth.com",
    "saint-lukes.org",
    "co.esses.ny.us",
    "spectrumhealth.org",
    "wcchc.com",
    "ravnah.org",
    "centrahealth.org",
    "chs.trihealth.com",
    "vnaindiana.org",
    "emh.org",
    "shannonhealth.org",
    "bsmhealth.org",
    "karmanos.org",
    "careathomesolutions.com",
    "wwhealth.org",
    "vna.phmc.org",
    "sandc.com",
    "cvhhh.org",
    "homehealth.org",
    "marshall.edu",
    "customcarehh.org",
    "dignityhealth.org",
    "hmhn.org",
    "frhs.org",
    "heartland-health.com",
    "riversidehealthcare.org",
    "pah.org",
    "angelahospice.us",
    "meridianhealth.com",
    "silvercross.org",
    "easternhealth.ca",
    "highmarkhealth.org",
    "residentialhospice.com",
    "transitionslifecare.org",
    "hcr-manorcare.com",
    "ahmgt.com",
    "uhs-sa.com",
    "apprhs.org",
    "masonicvillages.org",
    "wshc.org",
    "mainebehavioralhealthcare.org",
    "emhs.org",
    "catholichealth.net",
    "meritan.org",
    "vnahsr.org",
    "rcchc.org",
    "rsfh.com",
    "ftmc.com",
    "rhc.net",
    "lightways.org",
    "msn.com",
    "uphs.upenn.edu",
    "pennstatehealth.psu.edu",
    "rvhhh.com",
    "vahs.com",
    "blue-cirrus.com",
    "health-partners.org",
    "dignityhealth.com",
    "bigbendhospice.org",
    "interim-health.com",
    "eden-health.com",
    "homecareofthegrandvalley.org",
    "jcblair.org",
    "iredellmemorial.org",
    "iredellhealth.org",
    "pvillehosp.org",
    "memorial.org",
    "achhh.org",
    "conduithp.com",
    "unchealth.unc.edu",
    "unch.unc.edu",
    "brookdaleliving.com",
    "pihhealth.org",
    "nahealth.org",
    "pennantgroup.com",
    "umich.edu",
    "st-marys.org",
    "vnahomehealth.org",
    "deltahcmgmt.com",
    "hmhcs.com",
    "rexhealth.com",
    "keystonecare.com",
    "elysianhospice.com",
    "retreathealthcareservices.com",
    "pennantservices.com",
    "osptahome.com",
    "palliativecare.org",
    "athome.bilh.org",
    "trucare.org",
    "chhi.org",
    "drmc.org",
    "pmcamd.com",
    "ahsys.org",
    "msha.com",
    "nyp.org",
    "guttenberghospital.org",
    "premiermonroe.com",
    "greatlakescaring.com",
    "augustamed.com",
    "cantexcc.com",
    "midmichigan.org",
    "ehr.org",
    "co.essex.ny.us",
    "elliot-hs.org",
    "atriumhealth.org",
    "ohioliving.org",
    "ascension.org",
    "med.unc.edu",
    "kentuckyonehealth.org",
    "ufl.edu",
    "seamarchc.org",
    "ecommunity.com",
    "mah.org",
    "customcare.org",
    "hospiceswmi.org",
    "peds.bsd.uchicago.edu",
    "cchh08.com",
    "interimcares.com",
    "tidalhealth.org",
    "essexcountyny.gov",
    "providencehospital.org",
    "lghealth.org",
    "impact-advisors.com",
    "bidplymouth.org",
    "nashoba.org",
    "visitingnurseservice.org",
    "drhhealth.org",
    "mhemail.org",
    "healthkeeperz.com",
    "ecu.edu",
    "healtheast.org",
    "indianriverhomecare.com",
    "caredimesions.org",
    "hoic.org",
    "qualityhomehealth.com",
    "wellforce.org",
    "slvrmc.org",
    "verizon.net",
    "orthocarolina.com",
    "centrahealth.com",
    "hancockregional.org",
    "rochesterregional.org",
    "lakelandhealth.org",
    "communityvna.com",
    "mercy.net",
    "careresource.org",
    "pennhospice.com",
    "cesa7.org",
    "heartofagiant.org",
    "bmcjax.com",
    "caperegional.com",
    "sshosp.org",
    "behealthyathome.com",
    "pswipa.com",
    "trinity-health.org",
    "ufhealthrehabhospital.com",
    "hch.org",
    "homehealthfoundation.org",
    "infinitycares.net",
    "vnaalliance.org",
    "granitevna.org",
    "lacunahealth.com",
    "brainerdlakeshealth.org",
    "umhwest.org",
    "concordiavn.org",
    "superiorhospice.com",
    "pugetsoundhh.com",
    "tuftsmedicine.org",
    "stonybrookmedicine.edu",
    "tandem365.com",
    "miltonhospital.org",
    "mercyhealth.com",
    "devotedguardians.com",
    "bidmc.harvard.edu",
    "pih.net",
    "athoment.com",
    "provak.org",
    "advantushp.com",
    "frederick.health",
    "mckennan.org",
    "bidneedham.org",
    "lancastergeneral.org",
    "visitingnurses.org",
    "altacorp.com",
    "wcgh.org",
    "kinderhearts.com",
    "gallagherhhs.com",
    "helionhealthcare.com",
    "css.edu",
    "redstone.org",
    "vnshs.org",
    "jhhc.com",
    "stcharleshome.net",
    "bnws.co",
    "jchok.com",
    "smha.org",
    "premierbastrop.com",
    "ensigngroup.net",
    "darlingshomecare.com",
    "ahhh.org",
    "gunet.georgetown.edu",
    "banneraetna.com",
    "apollomedical.net",
    "rbmc.org",
    "corp.oprs.org",
    "camillushealth.org",
    "y-chc.org",
    "mercydesmoines.org",
    "vanderbilt.edu",
    "trivergenthealth.com",
    "bbmc.org",
    "summitwestcare.com",
    "saintfancis.com",
    "osptainc.com",
    "lowellgeneral.org",
    "seaportscripps.com",
    "smhc.org",
    "frontiernet.net",
    "psu.edu",
    "qwestoffice.net",
    "nathealthcare.com",
    "wpahs.org",
    "statclinix.com",
    "nhs-healthlink.org",
    "hotmail.com",
    "tuftsmedicalcenter.org",
    "huntregional.org",
    "huronmedicalcenter.org",
    "riversidehealthcare.net",
    "uchicago.edu",
    "testne.com",
    "prospectmedical.com",
    "mhsnr.org",
    "mercyhealth.org",
    "empres.com",
    "frederickhealth.org",
    "hmis.org",
    "stjoe.org",
    "familycarevn.com",
    "shah-associates.com",
    "franklincony.org",
    "canshomehealth.com",
    "mymosaiclifecare.org",
    "beverlyhospital.org",
    "excelahealth.org",
    "methodisthealth.org",
    "evicore.com",
    "mpcp.com",
    "carolinacaring.org",
    "dupagemd.com",
    "orthocenter.com",
    "cornerstonehhh.com",
    "dakotacare.org",
    "adenacorporation.com",
    "newhavenhealthcare.com",
    "sdisresources.com",
    "jhsi.com",
    "aveanna.com",
    "dacc.uchicago.edu",
    "memorialhospitalnh.org",
    "ethoscare.org",
    "dhhs.nc.gov",
    "doctorschociefl.com",
    "pioneermedicalgroupflorida.com",
    "memorialhermann.org",
    "stemc.org",
    "celtichealthcare.com",
    "bchs.edu",
    "medstarvna.org",
    "seaporthhh.com",
    "vcu.edu",
    "barnabashealth.org",
    "ssm-select.com",
    "deancare.com",
    "charlestoncancer.com",
    "mac.com",
    "anesis.com",
    "jh.edu",
    "aspirehc.com",
    "hospicebg.org",
    "cookchildrens.org",
    "sharp.org",
    "porthuronhospital.org",
    "jax.ufl.edu",
    "umphysicians.umn.edu",
    "primariahealth.com",
    "homecareconnectors.com",
    "nlchi.nl.ca",
    "iupui.edu",
    "tnhomecare.com",
    "hma.com",
    "shhpens.org",
    "atlantichhealth.org",
    "psccs.org",
    "healthcareconnectors.com",
    "mdc.edu",
    "stcam.com",
    "josephineah.com",
    "filescansolutions.com",
    "nebh.org",
    "pioneermedicalgroup.com",
    "woosteroh.com",
    "medicine.bsd.uchicago.edu",
    "sbrmc.org",
    "concordiahcbs.org",
    "vnacarenetwork.com",
    "sentera.com",
    "porterhills.org",
    "ecare-solutions.com",
    "carevalues.com",
    "pennantservices.net",
    "communicarehc.org",
    "email.unc.edu",
    "msadvancedmedicine.com",
    "surgery.bsd.uchicago.edu",
    "nascentiahealth.org",
    "centralhealth.nl.ca",
    "chw.edu",
    "hallmarkhealth.org",
    "pph.org",
    "rmchospital.com",
    "imgdigitalagency.com",
    "erlanger.org",
    "charter.net",
    "mlh.org",
    "covhs.org",
    "bmg.md",
    "thehenryford.org",
    "medstarmontgomery.org",
    "mercyone.org",
    "cigna.com",
    "spectrum-health.org",
    "pojafwgeuwhfgoihawg.com",
    "alliancecable.net",
    "iowaheart.com",
    "josephinecc.com",
    "franklincountyny.gov",
    "jeffersonregional.com",
    "hackensackumc.org",
    "greatlakeshomehealth.com",
    "ecuhealth.org",
    "ceenta.com",
    "winchesterhospital.org",
    "glhhs.com",
    "microtech.net",
    "midlandcc.org",
    "allinahealthaetna.com",
    "phm.org",
    "arh.org",
    "perimitertrax.com",
    "trihealth.com",
    "angelahospice.us",
    "drhhealth.org",
    "commonheart.us",
    "sutterhealth.org",
    "pennstatehealth.psu.edu",
    "bjc.org",
    "ssmhealth.com"
]