import './SelectOrderType.css'
import { IonButton, IonCard, IonCardHeader, IonCol, IonGrid, IonRow } from '@ionic/react';
import React  from 'react';

function SelectOrderType() {
    return (
      <IonCard>
        <IonCardHeader>Please select an order type:</IonCardHeader>
        <IonGrid>
            <IonRow>
                <IonCol>
                    <IonButton className='order-type-btn' expand='block' routerLink='/order/single/standard'>
                        New Order
                    </IonButton>
                </IonCol>
                <IonCol>
                    <IonButton className='order-type-btn' expand='block' routerLink='/order/single/return'>
                        Return
                    </IonButton>
                </IonCol>
                <IonCol>
                    <IonButton className='order-type-btn' expand='block' routerLink='/order/single/rma'>
                        RMA
                    </IonButton>
                </IonCol>
            </IonRow>
            <IonRow class='note-txt'>
                For help, contact&nbsp;<a href="mailto:support@healthrecoverysolutions.com\">support@healthrecoverysolutions.com</a> &nbsp;
            </IonRow>

        </IonGrid>
      </IonCard>
    );
  }
  export default SelectOrderType;