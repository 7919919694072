import './SelectOrderCount.css'
import { IonButton, IonCard, IonCardHeader, IonCol, IonGrid, IonRow } from '@ionic/react';
import React  from 'react';

function SelectOrderCount() {
    return (
      <IonCard>
        <IonCardHeader>Please select single or multiple patient order:</IonCardHeader>
        <IonGrid>
            <IonRow>
                <IonCol>
                    <IonButton id='single-order' className='order-count-btn' expand='block' routerLink='/order/single'>
                        Single Patient Order
                    </IonButton>
                    <IonRow class="row-text">Select for individual new orders, returns, RMAs</IonRow>
                </IonCol>
            </IonRow>

        </IonGrid>
      </IonCard>
    );
  }
  export default SelectOrderCount;