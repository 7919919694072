import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonPage } from '@ionic/react';
import Toolbar from '../components/ToolBar';
import React  from 'react';

const ThankYouPage: React.FC = () => {
    return (
      <IonPage>
        <Toolbar />
        <IonContent fullscreen>
          <IonCard>
          <IonCardHeader>
            <IonCardTitle>Thank you for submitting your order!</IonCardTitle>
          </IonCardHeader>
    
          <IonCardContent>
              <ul className="card-list">
                  <li>
                  You will receive an order confirmation email shortly.
                  </li>
                  <li>The order number and order status will be available in your daily Inventory Tracking Report tomorrow morning.</li>
                  <li>
                  Please direct any questions to support@healthrecoverysolutions.com.
                  </li>
                  <li>
                  Learn more about HRS at our homepage <a href='https://healthrecoverysolutions.com'>here</a>.
                  </li>
                  <li>
                  Place another order <a href='https://premium.shiphrs.com'>here</a>.
                  </li>
              </ul>
          </IonCardContent>
        </IonCard>
        </IonContent>
      </IonPage>
    );
  };
  
  export default ThankYouPage;