/* eslint-disable */
import { 
    IonButton, 
    IonCard, 
    IonCardContent, 
    IonCardTitle, 
    IonCheckbox, 
    IonContent, 
    IonInput, 
    IonItem, 
    IonLabel, 
    IonNote, 
    IonPage,
    IonSelect,
    IonTitle,
    IonSelectOption,  } from '@ionic/react';
import Toolbar from '../components/ToolBar';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useHistory } from 'react-router-dom'
import { validStates, validEnvs, validDomains} from '../common_data';
import React, { useEffect, useState } from 'react';

const test_zap_url = 'https://hooks.zapier.com/hooks/catch/2277921/3hrcptl/'
const prod_zap_url = 'https://hooks.zapier.com/hooks/catch/2277921/3hiopgs/'

function getCurrentDateTime() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}`;
}

async function createSpreadsheetRow(data: any, orderSubmitted: string) {

    if (data['resubmission'] === true && !data['orderNumberResubmission']) {
        data['orderNumberResubmission'] = 'Y'
    }

    const payload =  {
        organization: data['ordererOrganization'],
        clinicianName: data['ordererName'],
        clinicianEmail: data['ordererEmail'],
        clinicianPhoneNumber: data['ordererPhoneNumber'].replace(/\D/g, ''),
        inventorySubset: data['inventorySubset'],
        date: orderSubmitted,
        kitContents: 'N/A',
        warehouseComments: data['warehouseComments'],
        patientName: data['patientName'],
        phoneNumber: data['patientPhoneNumber'].replace(/\D/g, ''),
        address1: data['patientAddress1'],
        address2: data['patientAddress2'],
        city: data['patientCity'],
        state: data['patientState'],
        zip: data['patientPostalCode'],
        patientId: data['patientId'],
        serviceType: 'Ground',
        hrsKitNumber: data['inventoryNumber'],  //reference 1
        supportTicketNumber: 'N/A',
        orderType: 'Return',
        itemWithIssue: 'N/A',
        issueType: 'N/A',
        virtualInstall: 'N/A',
        returnsCoordination: !data['returnsCoordinationOptOut'],
        resubmission: data['resubmission'], //add col
        orderNumberResubmission: data['orderNumberResubmission'],
        orderNumberWithIssue: 'N/A',
        tier: 'Premium',
        needReplacement: 'N/A',
    }

    const res = await fetch(prod_zap_url, {
        method: "POST",
        body: JSON.stringify(payload)
    });

    if (!res.ok) {
        const message = `An error has occured: ${res.status}`;
        throw new Error(message);
    }

    return await res.json();
}




const StandardReturn: React.FC = () => {
    const history = useHistory();
    const [isResubmission, setIsResumbission] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    
    const handleResubmissionChange = (e: any) => {
        setValue('resubmission', e.detail.checked);
        setIsResumbission(e.detail.checked);
    };

    const {
        handleSubmit,
        control,
        setValue,
        register,
        getValues,
        formState: { isValid, errors }
      } = useForm({
        defaultValues: {
          ordererName: '',
          ordererPhoneNumber: '',
          ordererEmail: '',
          ordererOrganization: '',
          inventoryNumber: '',
          shippingSpeedSelect: 'Ground',
          patientName: '',
          patientPhoneNumber: '',
          patientAddress1: '',
          patientAddress2: '',
          patientCity: '',
          patientState: '',
          patientPostalCode: '',
          patientId: '',
          inventorySubset: '',
          warehouseComments: '',
          resubmission: false,
          orderNumberResubmission: '',
          returnsCoordinationOptOut: false
        }
      });


        useEffect(() => {
            setIsFormValid(isValid);
        }, [isValid]);
      

      /**
       *
       * @param data
       */
      const onSubmit = (data: any) => {
        var orderSubmitted = getCurrentDateTime();
    
        createSpreadsheetRow(data, orderSubmitted).then(res => {
            history.push('/thanks')
        }).catch(error => {
            console.log(error.message);
            alert(`${error.message}: please contact support@healthrecoverysolutions.com`)
        });
      };

    return (
      <IonPage>
        <Toolbar />
        <IonContent fullscreen className="ion-padding">
        <IonTitle class='order-title'>Return Order Form</IonTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <IonCard>
                    <IonCardContent>
                        <IonItem>
                            <IonLabel>Orderer Name * </IonLabel>
                            <IonInput
                                {...register('ordererName', {
                                required: 'Orderer Name is a required field',
                                maxLength: 100
                                })}
                            />
                            {errors.ordererName && errors.ordererName.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                        </IonItem>
                        <ErrorMessage
                        errors={errors}
                        name="ordererName"
                        as={<div style={{ color: 'red' }} />}
                        />

                        <IonItem>
                            <IonLabel>Orderer Email * </IonLabel>
                            <IonInput
                                {...register('ordererEmail', {
                                required: 'Orderer Email is a required field',
                                validate: (value, _) => validDomains.includes(value.split('@')[1].toLowerCase()) || "This is not a valid email. For help, please call 551-203-0011 or email support@healthrecoverysolutions.com",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: 'Invalid email address'
                                },
                                maxLength: 100
                                })}
                            />
                            {errors.ordererEmail && errors.ordererEmail.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                        </IonItem>
                        <ErrorMessage
                        errors={errors}
                        name="ordererEmail"
                        as={<div style={{ color: 'red' }} />}
                        />
                        <IonItem lines='none'>
                            <IonNote>
                                Note: This email address will receive shipping updates
                            </IonNote>
                        </IonItem>

                        <IonItem>
                            <IonLabel>Orderer Phone Number *</IonLabel>
                            <IonInput
                                {...register('ordererPhoneNumber', {
                                required: 'Orderer Phone Number is a required field',
                                pattern: {
                                    value: /^(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/gm,
                                    message: 'Invalid phone number'
                                }
                                })}
                            />
                        </IonItem>
                        <ErrorMessage
                        errors={errors}
                        name="ordererPhoneNumber"
                        as={<div style={{ color: 'red' }} />}
                        />

                        <IonItem>
                            <IonLabel>Orderer Organization * </IonLabel>
                            <IonInput
                                {...register('ordererOrganization', {
                                    required: 'Orderer Organization is a required field',
                                    validate: (value, _) => validEnvs.includes(value) || "This is not a valid name. For help, please call 551-203-0011 or email support@healthrecoverysolutions.com"
                                })}
                            />
                        </IonItem>
                        <ErrorMessage
                        errors={errors}
                        name="ordererOrganization"
                        as={<div style={{ color: 'red' }} />}
                        />
                        <IonItem>
                            <IonLabel>Inventory Subset </IonLabel>
                            <IonInput
                                {...register('inventorySubset')}
                            />
                        </IonItem>
                    </IonCardContent>
                </IonCard>

                <IonCard>
                    <IonCardTitle class="card-title">What are you returning?</IonCardTitle>
                    <IonCardContent>
                        <IonItem>      
                            <IonLabel text-wrap>
                                HRS Kit Number *
                            </IonLabel> 
                            <IonInput
                                {...register('inventoryNumber', {
                                required: 'Inventory number is a required field',
                                pattern: {
                                    value: /^[0-9]{5}$/i,
                                    message: 'Invalid inventory number'
                                }
                                })}
                            />
                        </IonItem>
                        <IonItem lines='none'>
                            <IonNote>
                                5-digit "HRSKIT" number usually located on the back of your devices
                            </IonNote>
                        </IonItem>
                        <ErrorMessage
                            errors={errors}
                            name="inventoryNumber"
                            as={<div style={{ color: 'red' }} />}
                        />
                        
                        
                    </IonCardContent>
                </IonCard>

                <IonCard>
                    <IonCardContent>
                        <IonItem>
                            <IonLabel>ClinicianConnect Patient ID</IonLabel>
                            <IonInput
                                {...register('patientId')}
                            />
                        </IonItem>
                        <IonItem>
                            <IonLabel text-wrap>Opt-out of returns coordination service for this order?</IonLabel>
                            <Controller
                                name="returnsCoordinationOptOut"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <IonCheckbox
                                            checked={field.value}
                                            onIonChange={e => {
                                                setValue('returnsCoordinationOptOut', e.detail.checked);
                                            }}
                                        />
                                    );
                                }}
                            />
                        </IonItem>
                        <IonItem lines='none'>
                            <IonNote>Your organization utilizes the Returns Coordination service in which the pickup phone number provided will receive outreach via phone call and/or text to coordinate equipment return. Opt-out to NOT receive the service on this order only.</IonNote>
                        </IonItem>
                    </IonCardContent>
                </IonCard>
            
                
                <IonCard>
                    <IonCardContent>
                        <IonCardTitle class="card-title">Pickup Address</IonCardTitle>
                        <IonItem>
                            <IonLabel>Full Name *</IonLabel>
                            <IonInput
                                {...register('patientName', {
                                required: 'Full Name is a required field',
                                maxLength: 100
                                })}
                            />
                            {errors.patientName && errors.patientName.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                        </IonItem>
                        <ErrorMessage
                        errors={errors}
                        name="patientName"
                        as={<div style={{ color: 'red' }} />}
                        />

                        <IonItem>
                            <IonLabel>Phone Number *</IonLabel>
                            <IonInput
                                {...register('patientPhoneNumber', {
                                required: 'Phone Number is a required field',
                                pattern: {
                                    value: /^(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/gm,
                                    message: 'Invalid phone number'
                                }
                                })}
                            />
                        </IonItem>
                        <ErrorMessage
                        errors={errors}
                        name="patientPhoneNumber"
                        as={<div style={{ color: 'red' }} />}
                        />

                        <IonItem>
                            <IonLabel>Address 1 * </IonLabel>
                            <IonInput
                                {...register('patientAddress1', {
                                required: 'Address 1 is a required field',
                                maxLength: 30
                                })}
                            />
                            {errors.patientAddress1 && errors.patientAddress1.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                        </IonItem>
                        <ErrorMessage
                        errors={errors}
                        name="patientAddress1"
                        as={<div style={{ color: 'red' }} />}
                        />

                        <IonItem>
                            <IonLabel>Address 2 </IonLabel>
                            <IonInput
                                {...register('patientAddress2', {
                                    maxLength: 30
                                })}
                            />
                            {errors.patientAddress2 && errors.patientAddress2.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                        </IonItem>

                        <IonItem>
                            <IonLabel>City * </IonLabel>
                            <IonInput
                                {...register('patientCity', {
                                required: 'City is a required field',
                                maxLength: 25
                                })}
                            />
                            {errors.patientCity && errors.patientCity.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                        </IonItem>
                        <ErrorMessage
                        errors={errors}
                        name="patientCity"
                        as={<div style={{ color: 'red' }} />}
                        />

                        <IonItem>
                            <IonLabel>State *</IonLabel>
                            <IonInput
                                {...register('patientState', {
                                    required: 'State is a required field',
                                    pattern: {
                                        value: /^[A-Z]{2}$/i,
                                        message: 'Invalid State code, please use two capital letters',
                                    },
                                    validate: (value, _) => validStates.includes(value.toUpperCase()) || "Invalid State"
                                })}
                            />
                        </IonItem>
                        <ErrorMessage
                            errors={errors}
                            name="patientState"
                            as={<div style={{ color: 'red' }} />}
                        />

                        <IonItem>
                            <IonLabel>Postal Code *</IonLabel>
                            <IonInput
                                {...register('patientPostalCode', {
                                    required: 'Postal Code is a required field',
                                    pattern: {
                                        value: /^[0-9]{5}(?:-[0-9]{4})?$/i,
                                        message: 'Invalid postal code'
                                    }
                                })}
                            />
                        </IonItem>
                        <ErrorMessage
                            errors={errors}
                            name="patientPostalCode"
                            as={<div style={{ color: 'red' }} />}
                        />

                       
                    </IonCardContent>
                </IonCard>

                <IonCard>
                    <IonCardContent>
                        <IonCardTitle class="card-title">
                            Additional Information
                        </IonCardTitle>
                        <IonItem>
                            <IonLabel text-wrap>Notes for Warehouse Team </IonLabel>
                            <IonInput
                                {...register('warehouseComments', {
                                    maxLength: 35
                                })}
                            />
                            {errors.warehouseComments && errors.warehouseComments.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }

                        </IonItem>
                    </IonCardContent>
                </IonCard>

                <IonCard>
                    <IonCardContent>
                        {Object.keys(errors).length > 0 && (
                                <div style={{ color: 'red' }}>Invalid entries, please check information entered and try again</div>
                        )}
                        <IonButton className="order-form-btn" expand="block" type="submit">
                            Submit Order
                        </IonButton> 
                        <IonItem lines='none'>
                                <IonNote>
                                    <ul>
                                        <li>For return requests submitted by 1:00 PM ET, the first pickup attempt will be on the following business day.</li>
                                        <li>All orders shipped Ground speed.</li>
                                    </ul>
                                </IonNote>
                            </IonItem>
                    </IonCardContent>
                </IonCard>

                <IonCard>
                    <IonCardContent>
                        <IonNote>
                            HRS Internal Use Only    
                        </IonNote> 
                        <IonItem>     

                            <IonLabel text-wrap>
                                Is this a re-submission of an existing return order?
                            </IonLabel> 
                            <Controller
                                name="resubmission"
                                control={control}
                                render={({ field }) => {
                                return (
                                    <IonCheckbox
                                    checked={isResubmission}
                                    onIonChange={handleResubmissionChange}
                                    />
                                );
                                }}
                            />
                        </IonItem>
                        {isResubmission && (
                            <IonItem lines='none'>
                                <IonLabel text-wrap>What order number are you re-submitting?</IonLabel>
                                <Controller
                                    control={control}
                                    name="orderNumberResubmission"
                                    render={({ field }) => (
                                    <IonInput {...register('orderNumberResubmission')} placeholder="Enter order number" />
                                    )}
                                />
                            </IonItem>                    
                        )}
                        {isResubmission && (
                            <IonItem lines='none'>
                                <IonNote>You can find this number in your inventory tracking report or on the bottom left of the shipping label on the kit box</IonNote>
                            </IonItem>
                        )}

                    </IonCardContent>
                    

                </IonCard>
            </form>
        </IonContent>
      </IonPage>
    );
  };
  
  export default StandardReturn;